import React from 'react';
import { Loader, Table } from 'semantic-ui-react';
import { isEmpty, startCase } from 'lodash';
import { Payment, PartnerPaymentsTableColumn } from 'pages/Payments/types';

interface PartnerPaymentsTableProps {
  paymentsList: Payment[];
  isLoading: boolean;
}

const tableColumns: PartnerPaymentsTableColumn[] = [
  { name: 'Payable App ID', key: 'id' },
  { name: 'Partner Name', key: 'alias' },
  { name: 'Payment Type', key: 'processor' },
  { name: 'Status', key: 'status' },
  { name: 'Modified Date', key: 'modifiedDate' },
  { name: 'Modified Author', key: 'modifiedAuthor' },
];

const PartnerPaymentsTable = ({ paymentsList, isLoading }: PartnerPaymentsTableProps) => {
  if (isLoading) return <Loader active size="massive" inline="centered" />;
  if (isEmpty(paymentsList)) return <p className="text-center font-montserrat">No payments available</p>;

  const tableBody = (
    <Table.Body className="font-montserrat text-sm">
      {paymentsList.map(payment => (
        <Table.Row key={payment.id}>
          {tableColumns.map(tableColumn => {
            const cellContent =
              tableColumn.key === 'alias'
                ? startCase(payment[tableColumn.key]) // Capitalize partner name
                : tableColumn.key === 'modifiedDate'
                  ? payment[tableColumn.key].split('T')[0] // Remove time from modified date
                  : payment[tableColumn.key];
            const cellClassName = [
              tableColumn.key === 'alias' ? '!text-left' : '!text-left md:!text-center',
              payment[tableColumn.key] === 'ACTIVE' ? 'text-success-green' : '',
              payment[tableColumn.key] === 'DISABLED' ? 'text-failed-red' : '',
              '!py-3',
            ].join(' ');
            return (
              <Table.Cell key={tableColumn.key} className={cellClassName}>
                <span className="font-semibold visible md:!hidden">{`${tableColumn.name}: `}</span>
                {cellContent}
              </Table.Cell>
            );
          })}
        </Table.Row>
      ))}
    </Table.Body>
  );
  return (
    <div className="font-montserrat text-sm whitespace-nowrap w-full max-w-[96vw] rounded-md overflow-auto">
      <Table compact celled striped aria-label="bonus payments table">
        <Table.Header className="hidden md:!table-header-group">
          <Table.Row>
            {tableColumns.map(tableColumn => (
              <Table.HeaderCell className="!text-center" key={tableColumn.key}>
                {tableColumn.name}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        {tableBody}
      </Table>
    </div>
  );
};

export default PartnerPaymentsTable;
