import React from 'react';
import PartnerPaymentsTable from './PartnerPaymentsTable';
import { Payment } from 'pages/Payments/types';

interface PartnerPaymentsProps {
  paymentsList: Payment[];
  isLoading: boolean;
}

const PartnerPayments = ({ paymentsList, isLoading }: PartnerPaymentsProps) => {
  return (
    <div className="w-full p-5">
      <h3 className="font-montserrat font-semibold mb-8">Partner Payments</h3>
      <PartnerPaymentsTable paymentsList={paymentsList} isLoading={isLoading} />
    </div>
  );
};

export default PartnerPayments;
